var render = function render(){
  var _vm$teamView2, _vm$teamView2$getCont;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Page', {
    attrs: {
      "title": _vm.teamName,
      "apiStatusList": [_vm.getTeamStatus],
      "breadcrumbs": _vm.breadcrumbs
    },
    scopedSlots: _vm._u([_vm.hasSubtitle ? {
      key: "subtitle",
      fn: function () {
        var _vm$teamView, _vm$teamView$getConte;
        return [_c('router-link', {
          attrs: {
            "to": '/contests/' + ((_vm$teamView = _vm.teamView) === null || _vm$teamView === void 0 ? void 0 : (_vm$teamView$getConte = _vm$teamView.getContentPreview()) === null || _vm$teamView$getConte === void 0 ? void 0 : _vm$teamView$getConte.getUrl())
          }
        }, [_vm._v(" " + _vm._s(_vm.contestName) + " ")])];
      },
      proxy: true
    } : null], null, true)
  }, [_c('team-detail', {
    attrs: {
      "teamView": _vm.teamView,
      "contestUrl": (_vm$teamView2 = _vm.teamView) === null || _vm$teamView2 === void 0 ? void 0 : (_vm$teamView2$getCont = _vm$teamView2.getContentPreview()) === null || _vm$teamView2$getCont === void 0 ? void 0 : _vm$teamView2$getCont.getUrl(),
      "editableContest": _vm.editableContest
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }